import { graphql, Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import '../styles/index.css'

function Index({ data }: { data: { allMarkdownRemark: any } }) {
  const { allMarkdownRemark } = data
  console.log('allMarkdownRemark: ', allMarkdownRemark)

  return (
    <main>
      <ul>
        {allMarkdownRemark.edges.map(({ node }: any) => {
          return (
            <Link key={node.frontmatter.slug} to={node.frontmatter.slug}>
              {node.frontmatter.title}{' '}
            </Link>
          )
        })}
      </ul>
    </main>
  )
}

export default Index

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            date
            slug
            title
          }
        }
      }
    }
  }
`
function fetch(arg0: string) {
  throw new Error('Function not implemented.')
}
